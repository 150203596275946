.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 61px;
}

.text {
  display: flex;
  width: 310px;
  padding-bottom: 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  color: #000;
}

.title {
  margin: 0 !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: -0.48px;
}

.subtitle {
  margin: 0 !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.224px;
}

.image {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);

  video {
    object-fit: cover;
  }
}

@media (max-width: 900px) {
  .container {
    gap: 64px;
    align-self: stretch;
  }

  .image {
    video {
      width: 100%;
      height: auto;
    }
  }
}

@media (max-width: 500px) {
  .container {
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }

  .image {
    video {
      width: 100%;
      height: auto;
    }
  }

  .title {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.4px;
  }

  .subtitle {
    font-size: 14px;
    letter-spacing: -0.196px;
  }
}
