@import '../../styles/breakpoints.scss';

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  max-width: 1440px;
}

.header {
  display: flex;
  padding: 0 44px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;

  @media (max-width: 900px) {
    padding: 56px 24px 0px 24px;
  }

  @media (max-width: 500px) {
    padding: 56px 16px;
  }
}

.headerContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 64px;
  flex: 1 0 0;
  cursor: pointer;

  @media (max-width: 900px) {
    justify-content: center;
    align-items: center;
    gap: 44px;
    flex: 1 0 0;

    h3 {
      font-size: 32px;
      line-height: normal;
    }
  }

  @media (max-width: 500px) {
    align-items: center;

    gap: 56px;
    flex: 1 0 0;

    h3 {
      font-size: 32px;
      line-height: normal;
    }
  }
}

.title {
  margin: 0 !important;
  color: var(--black-color);
  text-align: center;
  font-family: Montserrat;
  font-size: 44px;
  font-style: normal;
  font-weight: 600;
  line-height: 76px;
  opacity: 0;
  transform: translateY(20px);
  transition: 0.8s;
}

.visible {
  opacity: 1;
  transform: translateY(0px);
}

.featuresContainer {
  display: flex;
  padding: 60px 180px 196px 180px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 88px;
  align-self: stretch;

  @media (max-width: 900px) {
    padding: 164px 24px;
    gap: 164px;
  }

  @media (max-width: 500px) {
    padding: 32px 16px 0px 16px;
    gap: 56px;
  }
}

.featuresOptions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  gap: 16px;
  align-self: stretch;
  flex-wrap: wrap;

  @media (max-width: 900px) {
    gap: 24px;
  }

  @media (max-width: 500px) {
    gap: 16px;

    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}

.featuresContent {
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}

.featuresContentVisible {
  opacity: 1;
}
