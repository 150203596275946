@import '../../styles/breakpoints.scss';

.container {
  display: flex;
  padding: 16px;
  align-items: center;

  gap: 12px;

  border-radius: 44px;

  cursor: pointer;

  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.168px;

  background: #f9f9f9;
  color: #4f4f4f;
  transition: all 0.12s ease-in-out;

  @media all and (min-width: $breakpoint-md) {
    &:hover {
      background: #efefef;
      color: #121212;
    }
  }

  &.selected {
    background: #5249d9;
    color: #fff;
  }
}

@media (max-width: 500px) {
  .container {
    width: 100%;
    justify-content: center;
  }
}
